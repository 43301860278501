import { ApplicationRef, ComponentRef, createComponent, DestroyRef, EnvironmentInjector, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { delay, Observable, of, Subscription } from 'rxjs';

import { Asset, AssetControllerService } from '@eroom/dam-angular-client';
import { JacidiUploadFilesPanelComponent, Process } from '@components/jacidi-components-lib';

@Injectable()
export class DamService {
  private processes: Partial<Process>[] = [];
  private data: { obs: Observable<Asset>; sub: Subscription }[] = [];
  private element: HTMLElement | undefined;
  private componentRef: ComponentRef<JacidiUploadFilesPanelComponent> | undefined;

  constructor(
    private injector: EnvironmentInjector,
    private applicationRef: ApplicationRef,
    private assetControllerService: AssetControllerService,
    private destroyRef: DestroyRef,
  ) {}

  addFile(file: File) {
    if (!this.element) {
      this.show();
    }
    this.processes.push({
      name: file.name,
      status: 'pending',
    });
    this.componentRef!.instance.cdRef.detectChanges();
    const obs = /*of(file.name).pipe(
      delay(3000),
    );*/ this.assetControllerService
      .saveAsset(undefined, undefined, file.name, undefined, undefined, undefined, file)
      .pipe(takeUntilDestroyed(this.destroyRef));
    this.data.push({
      obs,
      sub: this.subscribeToDataObservable(file.name, obs),
    });
  }

  private subscribeToDataObservable(name: string, obs: Observable<Asset>) {
    return obs.subscribe({
      next: () => {
        this.processes.find((process) => process.name == name)!.status = 'ready';
        this.componentRef!.instance.cdRef.detectChanges();
      },
      error: () => {
        this.processes.find((process) => process.name == name)!.status = 'error';
        this.componentRef!.instance.cdRef.detectChanges();
      },
    });
  }

  // Previous dynamic-loading method required you to set up infrastructure
  // before adding the element to the DOM.
  private show() {
    // Create element
    this.element = document.createElement('jacidi-upload-files-panel');

    // Create the component and wire it up with the element
    this.componentRef = createComponent(JacidiUploadFilesPanelComponent, {
      environmentInjector: this.injector,
      hostElement: this.element,
    });

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(this.componentRef.hostView);

    // Listen to events
    /*componentRef.instance.closed.subscribe(() => {
      document.body.removeChild(element);
      this.applicationRef.detachView(componentRef.hostView);
    });*/

    this.componentRef.instance.onRemove.subscribe((name: string) => {
      const i = this.processes.findIndex((process) => process.name == name);
      this.data[i].sub.unsubscribe();
      this.data.splice(i, 1);
      this.processes.splice(i, 1);
      this.componentRef!.instance.cdRef.detectChanges();
    });

    this.componentRef.instance.onRetry.subscribe((name: string) => {
      const i = this.processes.findIndex((process) => process.name == name);
      this.processes[i].status = 'pending';
      this.componentRef!.instance.cdRef.detectChanges();
      this.data[i].sub = this.subscribeToDataObservable(name, this.data[i].obs);
    });

    this.componentRef.instance.onCancel.subscribe((name: string) => {
      const i = this.processes.findIndex((process) => process.name == name);
      this.processes[i].status = 'error';
      this.componentRef!.instance.cdRef.detectChanges();
      this.data[i].sub.unsubscribe();
    });

    // Set the inputs
    this.componentRef.instance.process = this.processes;

    // Add to the DOM
    document.body.appendChild(this.element);
  }
}
